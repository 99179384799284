import { gql, useMutation } from "@apollo/client";
import { Box, CircularProgress, Container, Fade, Stack } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";
import ReactGA from "react-ga4";
import { jwtDecode } from "jwt-decode";

const REGISTER = gql`
  mutation RegisterLite($token: String!) {
    authToken: registerLite(token: $token)
  }
`;

function VerifyEmail() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [, setAuthToken] = useLocalStorage("authToken", null);

  const [register, { called }] = useMutation(REGISTER);

  useEffect(() => {
    if (called) return;
    const token = searchParams.get("token");
    if (!token) return navigate("/request-access");
    register({
      variables: { token },
      onCompleted({ authToken }) {
        ReactGA.event({
          category: "sign-up",
          action: "verified-email",
        });

        const decodedAuthToken = jwtDecode(authToken);
        if (decodedAuthToken.dat.isExistingCompany) {
          window.location = `https://app.intelimotor.com/sign-in?authToken=${authToken}&redirect=${encodeURIComponent("/guide")}`;
        } else {
          setAuthToken(authToken);
          setTimeout(() => {
            navigate("/");
          }, 500);
        }
      },
    });
  }, [called, searchParams, navigate, register, setAuthToken]);

  return (
    <Fade in style={{ transitionDelay: "200ms" }}>
      <Container
        maxWidth="xl"
        sx={{
          minHeight: "100vh",
          py: 4,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Stack spacing={12} width="100%">
          <Box textAlign="center">
            <Box
              component="img"
              src="https://app.intelimotor.com/logo.png"
              alt="Guide"
              maxWidth={240}
            />
          </Box>
          <Box textAlign="center">
            <CircularProgress sx={{ fontSize: 80 }} color="secondary" />
          </Box>
        </Stack>
      </Container>
    </Fade>
  );
}

export default VerifyEmail;
