import { Business, Store } from "@mui/icons-material";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import GettingStartedLayout from "./GettingStartedLayout";
import businessTypes from "../utils/businessTypes";

function GettingStartedBusinessName({ initialState, nextStep, prevStep }) {
  const [businessName, setBusinessName] = useState(
    initialState.businessName || "",
  );

  const question = useMemo(
    () =>
      businessTypes.find(
        (businessType) => businessType.type === initialState.businessType,
      )?.nameQuestion,
    [initialState.businessType],
  );

  return (
    <GettingStartedLayout
      image="/SPACESHIP.webp"
      noBottom
      nextStep={() => nextStep({ businessName })}
      prevStep={() => prevStep({ businessName })}
      disabled={!businessName || !businessName.trim()}
    >
      <Box>
        <Typography variant="h5">{question}</Typography>
      </Box>
      <Box>
        <TextField
          variant="outlined"
          fullWidth
          autoFocus
          value={businessName}
          onChange={(e) => setBusinessName(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {initialState.businessType === "business-unit" ? (
                  <Store />
                ) : (
                  <Business />
                )}
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </GettingStartedLayout>
  );
}

export default GettingStartedBusinessName;
