import { useTheme } from "@emotion/react";
import {
  Box,
  Grid,
  Fade,
  useMediaQuery,
  Stack,
  Button,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

function GettingStartedLayout({
  children,
  disabled,
  nextStep,
  prevStep,
  image,
  lg = 5,
  xl = 4,
  noImages,
  noImageTransformations,
  noBackgroundLogo,
  fullWidthImage,
  flexStart,
  nextStepText,
  noBottom,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [imageLoaded, setImageLoaded] = useState(false);
  const [saving, setSaving] = useState(null);
  const [searchParams] = useSearchParams();

  const variant = searchParams.get("variant") || "setup";

  return (
    <Fade in={imageLoaded || isMobile || noImages || variant === "settings"}>
      <Grid
        container
        spacing={2}
        sx={{ alignItems: flexStart ? "flex-start" : "center" }}
      >
        <Grid
          item
          xs={12}
          lg={variant === "setup" ? lg : 12}
          xl={variant === "setup" ? xl : 12}
          sx={{ pr: isMobile && variant === "setup" ? 4 : 0 }}
        >
          <Stack spacing={3}>
            {children}
            <Box sx={{ pt: 4 }}>
              {/* next step */}
              {nextStep && (
                <Button
                  variant="contained"
                  size="large"
                  onClick={async () => {
                    setSaving("next");
                    await nextStep();
                    setSaving(null);
                  }}
                  {...(!disabled && !saving
                    ? { className: "gradient" }
                    : { disabled: true })}
                >
                  {saving === "next" ? (
                    <CircularProgress size={24} />
                  ) : (
                    nextStepText ||
                    (variant === "setup" ? "Continuar" : "Guardar")
                  )}
                </Button>
              )}

              {/* prev step */}
              {variant === "setup" && prevStep && (
                <Button
                  color="inherit"
                  size="large"
                  onClick={async () => {
                    setSaving("prev");
                    await prevStep();
                    setSaving(null);
                  }}
                  sx={{ ml: 1 }}
                >
                  {saving === "prev" ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Regresar"
                  )}
                </Button>
              )}
            </Box>
          </Stack>
        </Grid>
        {!isMobile && !noImages && variant === "setup" && (
          <Grid item lg={1} xl={2} />
        )}
        {!isMobile && !noImages && variant === "setup" && (
          <Grid
            item
            lg={12 - lg - 1}
            xl={12 - xl - 2}
            sx={{ position: "relative" }}
          >
            <Box
              component="img"
              src={image}
              alt=""
              sx={
                fullWidthImage
                  ? {
                      width: "100%",
                    }
                  : (theme) => ({
                      maxWidth: "30vw",
                      transform: noImageTransformations
                        ? ""
                        : "translateX(100px) scale(1.5)",
                      [theme.breakpoints.up("xl")]: {
                        transform: noImageTransformations
                          ? ""
                          : "translateX(100px) scale(1.5)",
                      },
                      position: "absolute",
                      zIndex: -1,
                      bottom: noBottom ? "auto" : 0,
                      maxHeight: "35vh",
                    })
              }
              onLoad={() => setImageLoaded(true)}
            />
          </Grid>
        )}
      </Grid>
    </Fade>
  );
}

export default GettingStartedLayout;
