import { Box, Typography } from "@mui/material";
import GettingStartedLayout from "./GettingStartedLayout";

function GettingStartedWelcome({ nextStep }) {
  return (
    <GettingStartedLayout image="/SPACESHIP.webp" noBottom nextStep={nextStep}>
      <Box>
        <Typography variant="h5" sx={{ mb: 3 }}>
          Perfecto, ahora configuremos tu cuenta.
        </Typography>
        <Typography>
          Queremos conocerte un poco más, te tomará solo unos minutos.
        </Typography>
      </Box>
    </GettingStartedLayout>
  );
}

export default GettingStartedWelcome;
