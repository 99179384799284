import { gql, useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Clear, VerifiedUser } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import ReactGA from "react-ga4";

import useLocalStorage from "../hooks/useLocalStorage";
import GettingStartedLayout from "./GettingStartedLayout";
import { useTheme } from "@emotion/react";
import WhatsAppButton from "./WhatsAppButton";

const SEND_CODE = gql`
  mutation SendLiteRegistrationCode(
    $email: String!
    $phone: String!
    $recaptchaToken: String!
  ) {
    phoneVerificationId: sendLiteRegistrationCode(
      email: $email
      phone: $phone
      recaptchaToken: $recaptchaToken
      liteType: "guide"
    )
  }
`;

const SIGN_UP = gql`
  mutation SendLiteRegistrationToken(
    $name: String!
    $email: String!
    $phone: String!
    $phoneVerificationId: String!
    $phoneVerificationCode: String!
    $password: String!
    $locationSearch: String
    $liteType: String
    $companyName: String
    $jobTitle: String
    $emailOptOut: Boolean
  ) {
    sendLiteRegistrationToken(
      name: $name
      password: $password
      phone: $phone
      phoneVerificationId: $phoneVerificationId
      phoneVerificationCode: $phoneVerificationCode
      email: $email
      locationSearch: $locationSearch
      liteType: $liteType
      companyName: $companyName
      jobTitle: $jobTitle
      emailOptOut: $emailOptOut
    )
  }
`;

function SignUp() {
  const theme = useTheme();
  const isXl = useMediaQuery(theme.breakpoints.up("xl"));
  const location = useLocation();
  const navigate = useNavigate();
  const [storedEmail] = useLocalStorage("storedEmail", "");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email] = useState(storedEmail);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [optIn, setOptIn] = useState(true);
  const [errors, setErrors] = useState([]);
  const recaptchaRef = useRef(null);
  const [step, setStep] = useState("form");
  const [phoneVerificationCode, setPhoneVerificationCode] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!email) navigate("/request-access");
  }, [email, navigate]);

  const [sendCode, { loading: sendingCode, data }] = useMutation(SEND_CODE, {
    onCompleted(codeData) {
      ReactGA.event({
        category: "sign-up",
        action: "sent-verification-code",
        label:
          codeData?.phoneVerificationId === "whatsapp" ? "whatsapp" : "sms",
      });
      setStep("phone-verification");
    },
  });

  const [signUp, { loading }] = useMutation(SIGN_UP, {
    onCompleted() {
      if (step === "email-verification") {
        ReactGA.event({
          category: "sign-up",
          action: "resent-verification-email",
        });
        enqueueSnackbar(`Se reenvió el correo de verificación a ${email}`, {
          variant: "success",
        });

        return;
      }

      ReactGA.event({
        category: "sign-up",
        action: "sent-verification-email",
      });
      setStep("email-verification");
    },
    onError(err) {
      ReactGA.event({
        category: "sign-up",
        action: "invalid-verification-code",
      });
      setErrors([err.message]);
    },
  });

  function getcurrentErrors() {
    let currentErrors = [];
    if (!firstName || !firstName.trim())
      currentErrors = [...currentErrors, "firstName"];
    if (!lastName || !lastName.trim())
      currentErrors = [...currentErrors, "lastName"];
    if (!phoneNumber || !phoneNumber.trim())
      currentErrors = [...currentErrors, "phoneNumber"];
    if (phoneNumber && phoneNumber.trim() && phoneNumber.trim().length !== 10)
      currentErrors = [...currentErrors, "phoneNumber-format"];
    if (!jobTitle) currentErrors = [...currentErrors, "jobTitle"];
    if (!password) currentErrors = [...currentErrors, "password"];
    return currentErrors;
  }

  async function handleSendCode() {
    const currentErrors = getcurrentErrors();
    setErrors(currentErrors);
    if (currentErrors.length > 0) {
      ReactGA.event({
        category: "sign-up",
        action: "invalid-form",
        label: currentErrors[0],
      });

      return;
    }

    const recaptchaToken = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();

    sendCode({ variables: { email, phone: phoneNumber, recaptchaToken } });
  }

  async function handleSignUp() {
    const fullName = [firstName, lastName].join(" ");
    signUp({
      variables: {
        name: fullName,
        email,
        phone: phoneNumber,
        phoneVerificationId: data.phoneVerificationId,
        phoneVerificationCode,
        password,
        locationSearch: location.search,
        liteType: "guide",
        jobTitle,
        emailOptOut: !optIn,
      },
    });
  }

  return (
    <Box sx={{ overflow: "hidden" }}>
      <Container
        maxWidth="xl"
        sx={{
          minHeight: "100vh",
          alignItems: "center",
          display: step === "email-verification" ? "flex" : "block",
        }}
      >
        <Stack spacing={6} width="100%" py={6}>
          <Box textAlign="center">
            <Box
              component="img"
              src="https://app.intelimotor.com/logo.png"
              alt="Guide"
              maxWidth={240}
            />
          </Box>

          {["form", "phone-verification"].includes(step) && (
            <>
              <GettingStartedLayout image="/ACCESO.webp">
                <Box pb={4}>
                  <Typography variant="h5">¡Bienvenido!</Typography>
                  <Typography>
                    Usaremos estos datos para crear tu nueva cuenta.
                  </Typography>
                </Box>
                <TextField
                  variant="outlined"
                  label="Dirección de correo electrónico de trabajo"
                  fullWidth
                  type="email"
                  value={email}
                  onChange={() => {}}
                  helperText={
                    <>
                      <span>
                        Recibirás un correo de verificación en esta
                        dirección.&nbsp;
                      </span>
                      <Link component={RouterLink} to="/request-access">
                        Cambiar dirección.
                      </Link>
                    </>
                  }
                />
                <TextField
                  variant="outlined"
                  label="Nombre"
                  fullWidth
                  autoFocus
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  {...(errors.includes("firstName")
                    ? {
                        helperText: "Este campo es requerido.",
                        error: true,
                      }
                    : {})}
                />
                <TextField
                  variant="outlined"
                  label="Apellido"
                  fullWidth
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  {...(errors.includes("lastName")
                    ? {
                        helperText: "Este campo es requerido.",
                        error: true,
                      }
                    : {})}
                />
                <TextField
                  variant="outlined"
                  label="Número de WhatsApp"
                  fullWidth
                  type="phone"
                  value={phoneNumber}
                  onChange={(e) =>
                    setPhoneNumber(
                      e.target.value.replace(/\D/g, "").slice(0, 10),
                    )
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+52</InputAdornment>
                    ),
                  }}
                  helperText="Recibirás un código de verificación en este número."
                  {...(errors.includes("phoneNumber")
                    ? {
                        helperText: "Este campo es requerido.",
                        error: true,
                      }
                    : {})}
                  {...(errors.includes("phoneNumber-format")
                    ? {
                        helperText:
                          "Ingresa los 10 dígitos de tu número de teléfono.",
                        error: true,
                      }
                    : {})}
                />
                <TextField
                  variant="outlined"
                  label="Contraseña"
                  fullWidth
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  {...(errors.includes("password")
                    ? {
                        helperText: "Este campo es requerido.",
                        error: true,
                      }
                    : {})}
                />
                <Box>
                  <Typography sx={{ mb: 1 }} variant="body2">
                    ¿Cuál es tu área de trabajo?
                  </Typography>
                  {errors.includes("jobTitle") && (
                    <Typography
                      sx={{ mb: 1 }}
                      variant="caption"
                      color="error"
                      component="div"
                    >
                      Este campo es requerido.
                    </Typography>
                  )}
                  {[
                    "Ventas",
                    "Marketing",
                    "Sistemas",
                    "Administración",
                    "Adquisiciones",
                    "Dirección o gerencia",
                  ].map((item) => (
                    <Chip
                      key={item}
                      label={item}
                      variant="outlined"
                      onClick={() => setJobTitle(item)}
                      {...(jobTitle === item
                        ? {
                            variant: "filled",
                            color: "primary",
                            className: "gradient",
                          }
                        : {})}
                      sx={{ mr: 1, mb: 1 }}
                    />
                  ))}
                </Box>
                <Box>
                  <Divider />
                  <List>
                    <ListItem disableGutters>
                      <ListItemText
                        primary="¡No te quedes fuera!"
                        secondary="Conoce nuestras últimas actualizaciones, nuevos productos y accede a la información más reciente del mercado automotríz."
                      />
                      <ListItemSecondaryAction>
                        <Switch
                          checked={optIn}
                          onChange={(e) => setOptIn(e.target.checked)}
                          color="secondary"
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    disabled={sendingCode}
                    onClick={handleSendCode}
                    {...(!sendingCode ? { className: "gradient" } : {})}
                  >
                    Continuar
                  </Button>
                  {sendingCode && (
                    <LinearProgress
                      color="secondary"
                      sx={{
                        position: "relative",
                        top: "-4px",
                        opacity: 0.9,
                      }}
                    />
                  )}
                </Box>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey="6LeJi1EaAAAAABwzTYSJp4Z-rdNcf8RK5johhyxs"
                  hl="es-419"
                />
              </GettingStartedLayout>
              <Typography
                variant="caption"
                sx={{ textAlign: "center", pb: !isXl ? 4 : 0 }}
                component="div"
              >
                <span>
                  Este sitio está protegido por reCAPTCHA y se aplican la&nbsp;
                </span>
                <Link href="https://policies.google.com/privacy">
                  política de privacidad
                </Link>
                <span>&nbsp;y los&nbsp;</span>
                <Link href="https://policies.google.com/terms">
                  términos de servicio
                </Link>
                <span>
                  &nbsp;de Google. Al hacer clic en "Continuar", confirmas que
                  la información proporcionada es precisa y que estás de acuerdo
                  con nuestros&nbsp;
                </span>
                <Link href="https://guide.com/tyc/">
                  términos y condiciones de uso
                </Link>
                &nbsp;y&nbsp;
                <Link href="https://guide.com/tyc/">
                  política de privacidad
                </Link>
                . Tu privacidad es importante para nosotros y nunca
                compartiremos tus datos con terceros sin tu consentimiento.
              </Typography>
            </>
          )}

          {step === "email-verification" && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Box
                sx={(theme) => ({
                  backgroundImage:
                    "linear-gradient(to right, black 0%, #434343 100%)",
                  px: 3,
                  py: 3,
                  textAlign: "center",
                  [theme.breakpoints.up("md")]: {
                    textAlign: "left",
                    px: 8,
                    py: 6,
                  },
                })}
                maxWidth="800px"
                width="100%"
                borderRadius={4}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <Typography variant="h4" color="white" sx={{ mb: 4 }}>
                      ¡Listo! Ahora confirma tu
                      <br />
                      <Box
                        component="span"
                        sx={(theme) => ({
                          color: theme.palette.primary.main,
                        })}
                      >
                        correo electrónico
                      </Box>
                    </Typography>
                    <Typography color="white">
                      {`Te mandamos un correo de confirmación a ${email}. Por favor, búscalo en tu bandeja para continuar con tu proceso de registro a la Guía Intelimotor.`}
                    </Typography>
                  </Grid>
                  <Hidden mdDown>
                    <Grid item md={4}>
                      <Box
                        component="img"
                        src="https://app.intelimotor.com/icon.png"
                        alt=""
                        sx={{
                          width: 100,
                          height: 100,
                          transform:
                            "scale(2.8) translateX(50px) translateY(25px)",
                          filter:
                            "drop-shadow(0 4px 3px #00000032) drop-shadow(0 2px 2px #0000000f)",
                        }}
                      />
                    </Grid>
                  </Hidden>
                </Grid>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography sx={{ textAlign: "center" }}>
                  ¿No te llegó el correo? Puedes&nbsp;
                  {loading ? (
                    <CircularProgress size={16} />
                  ) : (
                    <Link href="#" onClick={handleSignUp}>
                      enviarlo de nuevo
                    </Link>
                  )}
                  &nbsp;o&nbsp;
                  <Link component={RouterLink} to="/request-access">
                    cambiar tu dirección.
                  </Link>
                </Typography>
              </Box>
            </Box>
          )}
        </Stack>
      </Container>

      {step === "phone-verification" && (
        <Dialog open onClose={() => setStep("form")} fullWidth maxWidth="xs">
          <DialogContent sx={{ textAlign: "center", position: "relative" }}>
            <IconButton
              onClick={() => setStep("form")}
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <Clear />
            </IconButton>
            <VerifiedUser sx={{ fontSize: "64px" }} color="primary" />
            <Typography variant="h5">Verifica tu número de teléfono</Typography>
            <Typography>
              {`Ingresa el código de verificación que te enviamos ${
                data?.phoneVerificationId === "whatsapp"
                  ? "a tu WhatsApp"
                  : "por SMS"
              }.`}
            </Typography>
            <Stack spacing={1} sx={{ mt: 4 }}>
              <TextField
                variant="outlined"
                label="Código de verificación"
                fullWidth
                type="number"
                value={phoneVerificationCode}
                onChange={(e) =>
                  setPhoneVerificationCode(
                    e.target.value.replace(/\D/g, "").slice(0, 6),
                  )
                }
                {...(errors.length
                  ? {
                      error: true,
                      helperText: "Código incorrecto, intenta de nuevo.",
                    }
                  : {})}
                disabled={loading}
                autoFocus
                onKeyDown={(e) => (e.key === "Enter" ? handleSignUp() : null)}
              />
              <Button
                variant="contained"
                color="primary"
                size="large"
                {...(!loading &&
                phoneVerificationCode &&
                phoneVerificationCode.length === 6
                  ? { className: "gradient" }
                  : {})}
                onClick={handleSignUp}
                disabled={
                  loading ||
                  !phoneVerificationCode ||
                  phoneVerificationCode.length !== 6
                }
              >
                {loading ? <CircularProgress size={24} /> : "Verificar"}
              </Button>
            </Stack>
          </DialogContent>
        </Dialog>
      )}

      <WhatsAppButton />
    </Box>
  );
}

export default SignUp;
