import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  LinearProgress,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import useLocalStorage from "../hooks/useLocalStorage";
import GettingStartedWelcome from "./GettingStartedWelcome";
import GettingStartedBusinessType from "./GettingStartedBusinessType";
import GettingStartedBusinessName from "./GettingStartedBusinessName";
import GettingStartedDone from "./GettingStartedDone";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import toPercentage from "../utils/toPercentage";
import WhatsAppButton from "./WhatsAppButton";
import ReactGA from "react-ga4";
import {
  CloudDoneOutlined,
  ExitToAppOutlined,
  MenuOpen,
} from "@mui/icons-material";
import moment from "moment";
import { jwtDecode } from "jwt-decode";
import { Link as RouterLink } from "react-router-dom";
import GettingStartedBusinessUnitsCount from "./GettingStartedBusinessUnitsCount";

export const GET_REMOTE_GETTING_STARTED_STATE = gql`
  query GetRemoteState {
    me {
      name
      email
      guideGettingStartedState
    }
  }
`;

export const GET_REMOTE_SETTINGS_STATE = gql`
  query GetRemoteState {
    me {
      name
      email
    }
  }
`;

export const SAVE_REMOTE_STATE = gql`
  mutation SaveRemoteState($state: String!) {
    changeGuideGettingStartedState(state: $state)
  }
`;

function GettingStarted() {
  const [searchParams] = useSearchParams();
  const [authToken, setAuthToken] = useLocalStorage("authToken", null);
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [maxStep, setMaxStep] = useState(step);
  const [lastStep, setLastStep] = useState(null);
  const [gettingStartedState, setGettingStartedState] = useState({});
  const [savedAt, setSavedAt] = useState(moment());
  const [prevStepsMenuTarget, setPrevStepsMenuTarget] = useState(null);
  const [isMaster, setIsMaster] = useState(false);
  const [ownProfile, setOwnProfile] = useState(null);

  const [getRemoteState] = useLazyQuery(GET_REMOTE_GETTING_STARTED_STATE, {
    onCompleted({ me }) {
      if (!me) {
        setAuthToken(null);
        setTimeout(() => navigate("/"), 100);
        return;
      }

      const remoteState = JSON.parse(me.guideGettingStartedState);
      setGettingStartedState(remoteState);
      setStep(remoteState.step || 1);
      setMaxStep(remoteState.maxStep || remoteState.step || 1);
      setOwnProfile({ name: me.name, email: me.email });

      if (!remoteState.step) {
        ReactGA.event({
          category: "getting-started",
          action: "first-step",
        });
      }
    },
  });

  useEffect(() => {
    const token = searchParams.get("authToken") || authToken;
    if (token) {
      setAuthToken(token);
      getRemoteState({
        context: {
          headers: {
            Authorization: ["Bearer", token].join(" "),
          },
        },
      });

      const decodedToken = jwtDecode(token);
      setIsMaster(decodedToken.dat.master || false);
    } else {
      setTimeout(() => navigate("/"), 100);
    }
  }, [authToken, searchParams, getRemoteState, setAuthToken, navigate]);

  const [saveRemoteState] = useMutation(SAVE_REMOTE_STATE, {
    context: { headers: { Authorization: ["Bearer", authToken].join(" ") } },
  });

  const steps = useMemo(
    () => [
      {
        name: "Welcome",
        component: GettingStartedWelcome,
        displayName: "Bienvenido",
      },
      {
        name: "BusinessType",
        component: GettingStartedBusinessType,
        displayName: "Tipo de negocio",
      },
      {
        name: "BusinessName",
        component: GettingStartedBusinessName,
        displayName: "Nombre de tu negocio",
      },
      ...(gettingStartedState.businessType === "Group"
        ? [
            {
              name: "BusinessUnitsCount",
              component: GettingStartedBusinessUnitsCount,
              displayName: "Tamaño de tu negocio",
            },
          ]
        : []),
      {
        name: "Done",
        component: GettingStartedDone,
      },
    ],
    [gettingStartedState.businessType],
  );

  async function saveState(data = {}, refetch = false) {
    const newState = { ...gettingStartedState, ...data };
    setSavedAt(null);
    setGettingStartedState(newState);
    const result = await saveRemoteState({
      variables: { state: JSON.stringify(newState) },
    });

    if (refetch) {
      await getRemoteState({
        fetchPolicy: "network-only",
        context: {
          headers: {
            Authorization: ["Bearer", authToken].join(" "),
          },
        },
      });
    }

    setSavedAt(moment());
    return result;
  }

  async function nextStep(data = {}, stepsForward = 1, refetch = false) {
    setLastStep(step);
    const newStep = step + stepsForward;
    const newMaxStep = Math.max(newStep, maxStep);
    const result = await saveState(
      {
        ...gettingStartedState,
        ...data,
        step: newStep,
        stepName: steps[newStep - 1]?.name,
        maxStep: newMaxStep,
      },
      refetch,
    );
    setStep(newStep);
    setMaxStep(newMaxStep);

    if (steps[newStep - 1]?.name) {
      ReactGA.event({
        category: "getting-started",
        action: "next-step",
        label: steps[newStep - 1]?.name,
      });
    }

    return result;
  }

  async function prevStep(data = {}, stepsBackwards = 1, refetch = false) {
    setLastStep(step);
    const newStep = step - stepsBackwards;
    let result = null;
    result = await saveState(
      {
        ...gettingStartedState,
        ...data,
        step: newStep,
        stepName: steps[newStep - 1]?.name,
      },
      refetch,
    );
    setStep(newStep);

    if (steps[newStep - 1]?.name) {
      ReactGA.event({
        category: "getting-started",
        action: "prev-step",
        label: steps[newStep - 1]?.name,
      });
    }
    return result;
  }

  const StepComponent = step > 0 ? steps[step - 1].component : <></>;

  return (
    <Box sx={{ overflow: "hidden" }}>
      <Container maxWidth="xl" sx={{ minHeight: "calc(100vh - 10px)" }}>
        <Stack spacing={6} width="100%" py={6}>
          <Box textAlign="center">
            <Box
              component="img"
              src="https://app.intelimotor.com/logo.png"
              alt="Logo de Intelimotor"
              maxWidth={240}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {isMaster && (
              <Tooltip title="Cerrar sesión">
                <IconButton component={RouterLink} to="/sign-out">
                  <ExitToAppOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            {step > 0 &&
              ((steps[step - 1].name !== "Done" &&
                !gettingStartedState.rescheduling) ||
                isMaster) && (
                <>
                  <Tooltip
                    title={
                      step === maxStep
                        ? "Regresar a un paso anterior"
                        : "Navegar a otro paso"
                    }
                  >
                    <IconButton
                      onClick={(e) => setPrevStepsMenuTarget(e.currentTarget)}
                    >
                      <MenuOpen />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    open={Boolean(prevStepsMenuTarget)}
                    anchorEl={prevStepsMenuTarget}
                    onClose={() => setPrevStepsMenuTarget(null)}
                  >
                    <Box sx={{ px: 2, pb: 1 }}>
                      <Typography variant="caption" color="textSecondary">
                        {step === maxStep
                          ? "No te preocupes, tu progreso no se perderá al regresar."
                          : "No te preocupes, tu progreso no se perderá al navegar."}
                      </Typography>
                    </Box>

                    {steps
                      .filter((item) => item.displayName)
                      .map((item, idx) => (
                        <MenuItem
                          key={item.name}
                          onClick={() => {
                            setPrevStepsMenuTarget(null);
                            prevStep({}, step - idx - 1, false);
                          }}
                          disabled={maxStep <= idx}
                          selected={idx === step - 1}
                        >
                          <ListItemIcon>{idx + 1}</ListItemIcon>
                          {item.displayName}
                        </MenuItem>
                      ))}
                  </Menu>
                </>
              )}
            <LinearProgress
              sx={{ flex: 1, height: 10, borderRadius: 2, mr: 1 }}
              variant="determinate"
              value={(100 / steps.length) * step}
              color="inherit"
            />
            {step > 0 && (
              <Typography variant="subtitle2">
                {toPercentage(((100 / steps.length) * step) / 100, 0)}
              </Typography>
            )}
            <Box sx={{ pl: 1, display: "flex", alignItems: "center" }}>
              <Tooltip title={savedAt ? `Progreso guardado` : null}>
                {savedAt ? (
                  <CloudDoneOutlined fontSize="small" color="secondary" />
                ) : (
                  <CircularProgress color="inherit" size={20} />
                )}
              </Tooltip>
            </Box>
          </Box>
          {step === 0 ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: 400,
              }}
            >
              <CircularProgress size={48} />
            </Box>
          ) : (
            <StepComponent
              initialState={gettingStartedState}
              prevStep={prevStep}
              nextStep={nextStep}
              saveState={saveState}
              lastStep={steps[lastStep - 1]?.name}
              ownProfile={ownProfile}
            />
          )}
        </Stack>
      </Container>
      <WhatsAppButton />
    </Box>
  );
}

export default GettingStarted;
