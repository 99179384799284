export const businessTypes = [
  {
    type: "Business Unit",
    label: "Concesionario individual",
    nameQuestion: "¿Cómo se llama tu Concesionario?",
  },
  {
    type: "Group",
    label: "Grupo de Concesionarios",
    nameQuestion: "¿Cómo se llama tu Grupo de Concesionarios?",
  },
  {
    type: "Bank",
    label: "Banco o financiera",
    nameQuestion: "¿Cómo se llama tu Banco o Financiera?",
  },
  {
    type: "Manufacturer",
    label: "Fabricante",
    nameQuestion: "¿De qué marca eres Fabricante?",
  },
  {
    type: "Association",
    label: "Asociación",
    nameQuestion: "¿Cómo se llama tu asociación?",
  },
];

export default businessTypes;
