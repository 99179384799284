import { Box, TextField, Typography } from "@mui/material";
import { useState } from "react";
import GettingStartedLayout from "./GettingStartedLayout";

function GettingStartedBusinessUnitsCount({
  initialState,
  nextStep,
  prevStep,
}) {
  const [businessUnitsCount, setBusinessUnitsCount] = useState(
    initialState.businessUnitsCount || "",
  );

  return (
    <GettingStartedLayout
      image="/SPACESHIP.webp"
      noBottom
      nextStep={() => nextStep({ businessUnitsCount })}
      prevStep={() => prevStep({ businessUnitsCount })}
      disabled={
        !businessUnitsCount ||
        !businessUnitsCount.trim() ||
        !(Number.parseInt(businessUnitsCount) > 0)
      }
    >
      <Box>
        <Typography variant="h5">
          {`¿Cuantos Concesionarios tiene ${initialState.businessName}?`}
        </Typography>
        <Typography>
          Es decir, la cantidad de unidades de negocio que compran o venden
          autos seminuevos.
        </Typography>
      </Box>
      <Box>
        <TextField
          variant="outlined"
          autoFocus
          type="number"
          value={businessUnitsCount}
          onChange={(e) =>
            setBusinessUnitsCount(e.target.value.replace(/\D/g, ""))
          }
          sx={{ mr: 1, width: 80 }}
          InputProps={{
            componentsProps: {
              input: {
                min: 1,
              },
            },
          }}
          placeholder="Ej. 2"
        />
      </Box>
    </GettingStartedLayout>
  );
}

export default GettingStartedBusinessUnitsCount;
