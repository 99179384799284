import { useEffect } from "react";

function SignIn() {
  useEffect(() => {
    window.location.replace(
      "https://app.intelimotor.com/sign-in?redirect=/guide",
    );
  }, []);

  return <></>;
}

export default SignIn;
