import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import { CssBaseline } from "@mui/material";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { SnackbarProvider } from "notistack";
import ReactGA from "react-ga4";
import "moment/locale/es";

import Router from "./components/Router";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./App.css";

ReactGA.initialize([{ trackingId: "G-QNFKY1EV3Z" }]);

const client = new ApolloClient({
  uri: window.location.href.includes("localhost")
    ? "http://localhost/graphql"
    : "https://app.intelimotor.com/graphql",
  cache: new InMemoryCache(),
});

const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#1a1b1d",
    },
    primary: {
      main: "rgb(50, 210, 255)",
      contrastText: "#fff",
    },
    secondary: {
      main: "rgb(0, 146, 255)",
      contrastText: "#fff",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        sizeLarge: {
          minHeight: 50,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: { fontWeight: "bold" },
        h2: { fontWeight: "bold" },
        h3: { fontWeight: "bold" },
        h4: { fontWeight: "bold" },
        h5: { fontWeight: "bold" },
        h6: { fontWeight: "bold" },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeMedium: {
          height: 40,
          borderRadius: 20,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: "none",
          "&:before": {
            display: "none",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          marginTop: "0 !important",
          marginBottom: "0 !important",
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        bar: {
          background:
            "linear-gradient(135deg, rgb(50, 210, 255) 30%, rgb(0, 146, 255) 100%)",
        },
      },
    },
  },
});

function App() {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <BrowserRouter>
            <>
              <CssBaseline />
              <Router />
            </>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
