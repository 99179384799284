import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { EmailOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Container,
  Fade,
  Grid,
  Hidden,
  InputAdornment,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { isCompanyEmail } from "company-email-validator";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import useLocalStorage from "../hooks/useLocalStorage";
import { useTheme } from "@emotion/react";
import WhatsAppButton from "./WhatsAppButton";
import ReactGA from "react-ga4";

const CHECK_USER_BY_EMAIL = gql`
  query CheckUserByEmail(
    $email: String!
    $utm_campaign: String
    $utm_source: String
    $utm_medium: String
    $utm_content: String
    $utm_term: String
    $isCompanyEmail: Boolean
  ) {
    checkUserByEmail(
      email: $email
      product: "guide"
      utm_campaign: $utm_campaign
      utm_source: $utm_source
      utm_medium: $utm_medium
      utm_content: $utm_content
      utm_term: $utm_term
      isCompanyEmail: $isCompanyEmail
    )
  }
`;

const SAVE_UNQUALIFIED_LEAD = gql`
  mutation SaveUnqualifiedLead($email: String!) {
    saveUnqualifiedLead(email: $email, product: "guide")
  }
`;

function RequestAccess() {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [storedEmail, setStoredEmail] = useLocalStorage("storedEmail", "");
  const [email, setEmail] = useState(
    new URLSearchParams(location.search).get("email") || storedEmail || "",
  );
  const [error, setError] = useState(null);

  // utms
  const [utmCampaign, setUtmCampaign] = useLocalStorage("utm_campaign", "");
  const [utmSource, setUtmSource] = useLocalStorage("utm_source", "");
  const [utmMedium, setUtmMedium] = useLocalStorage("utm_medium", "");
  const [utmContent, setUtmContent] = useLocalStorage("utm_content", "");
  const [utmTerm, setUtmTerm] = useLocalStorage("utm_term", "");

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);

    const newUtmCampaign = urlSearchParams.get("utm_campaign");
    if (newUtmCampaign) setUtmCampaign(newUtmCampaign);

    const newUtmSource = urlSearchParams.get("utm_source");
    if (newUtmSource) setUtmSource(newUtmSource);

    const newUtmMedium = urlSearchParams.get("utm_medium");
    if (newUtmMedium) setUtmMedium(newUtmMedium);

    const newUtmContent = urlSearchParams.get("utm_content");
    if (newUtmContent) setUtmContent(newUtmContent);

    const newUtmTerm = urlSearchParams.get("utm_term");
    if (newUtmTerm) setUtmTerm(newUtmTerm);
  }, [
    location.search,
    setUtmCampaign,
    setUtmSource,
    setUtmMedium,
    setUtmContent,
    setUtmTerm,
  ]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);

    if (urlSearchParams.get("email")) {
      handleCheck();
    }
  }, []);

  const [saveUnqualifiedLead] = useMutation(SAVE_UNQUALIFIED_LEAD);

  const [check, { loading }] = useLazyQuery(CHECK_USER_BY_EMAIL, {
    onCompleted(data) {
      if (!data.checkUserByEmail && !isCompanyEmail(email.trim())) {
        ReactGA.event({
          category: "request-access",
          action: "check-email",
          label: "unqualified",
        });
        saveUnqualifiedLead({ variables: { email } });
        return setError(
          <>
            {`¡Lo sentimos! Por el momento no es posible el registro con correos personales (gmail.com, outlook.com, etc).\n\nEsta herramienta está diseñada exclusivamente para profesionales de la industria automotriz. Te pedimos ingresar una dirección de correo electrónico de trabajo (ej. jaime@autosmexico.com).`
              .split("\n")
              .map((line) => (
                <>
                  <span>{line}</span>
                  <br />
                </>
              ))}
          </>,
        );
      }

      ReactGA.event({
        category: "request-access",
        action: "check-email",
        label: data.checkUserByEmail ? "existing-user" : "new-user",
      });
      navigate(data.checkUserByEmail ? "/sign-in" : "/sign-up");
    },
  });

  function handleCheck() {
    if (!email || !email.trim() || !isEmail(email.trim())) {
      ReactGA.event({
        category: "request-access",
        action: "check-email",
        label: "invalid",
      });
      return setError(
        "Ingresa una dirección de correo electrónico de trabajo válida.",
      );
    }

    setError(null);
    setStoredEmail(email.trim());
    check({
      variables: {
        email: email.trim(),
        utm_campaign: utmCampaign,
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_content: utmContent,
        utm_term: utmTerm,
        isCompanyEmail: isCompanyEmail(email.trim()),
      },
      fetchPolicy: "network-only",
    });
  }

  return (
    <Fade in style={{ transitionDelay: "200ms" }}>
      <Container
        maxWidth="xl"
        sx={{
          minHeight: "100vh",
          py: 4,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Stack spacing={isMobile ? 6 : 12}>
          <Box textAlign="center">
            <Box
              component="img"
              src="https://app.intelimotor.com/logo.png"
              alt="Intelimotor"
              maxWidth={300}
            />
          </Box>
          <Grid
            container
            spacing={2}
            sx={isMobile ? { flexDirection: "column-reverse" } : {}}
          >
            <Grid
              item
              xs={12}
              md={6}
              lg={5}
              xl={4}
              sx={isMobile ? { pr: 4, mt: 4, mb: 2 } : {}}
            >
              <Stack spacing={2}>
                {!isMobile && (
                  <Box pb={4}>
                    <Typography variant="h5">
                      ¡Bienvenido a la revolución Intelimotor!
                    </Typography>
                    <Typography>
                      Prepárate para conquistar el mercado de autos seminuevos
                      con la Guía Intelimotor.
                    </Typography>
                  </Box>
                )}
                <TextField
                  variant="outlined"
                  label="Ingresa tu correo electrónico de trabajo"
                  fullWidth
                  autoFocus
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value.toLowerCase())}
                  disabled={loading}
                  error={Boolean(error)}
                  helperText={error}
                  onKeyDown={(e) => (e.key === "Enter" ? handleCheck() : null)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
                <Box>
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    disabled={loading}
                    onClick={handleCheck}
                    {...(!loading ? { className: "gradient" } : {})}
                  >
                    Acceder
                  </Button>
                  {loading && (
                    <LinearProgress
                      color="secondary"
                      sx={{ position: "relative", top: "-4px", opacity: 0.9 }}
                    />
                  )}
                </Box>
                <Typography
                  variant="caption"
                  sx={{ pt: 3, textAlign: "justify", lineHeight: 1.4 }}
                  color="textSecondary"
                >
                  <span>
                    Al ingresar tu correo electrónico confirmas que aceptas que
                    Ventau se comunique contigo acerca de sus productos y
                    servicios. Puedes darte de baja en cualquier momento
                    haciendo clic en "darse de baja" en nuestros mensajes de
                    correo electrónico. Obtén más información sobre cómo usamos
                    tus datos en nuestra&nbsp;
                  </span>
                  <Link href="https://intelimotor.com/privacy/">
                    política de privacidad
                  </Link>
                  .
                </Typography>
              </Stack>
            </Grid>
            <Hidden lgDown>
              <Grid item lg={1} xl={2} />
            </Hidden>
            <Grid item xs={12} md={6} sx={isMobile ? { pr: 4 } : {}}>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      sx={{ width: 24, height: 24, mr: 2 }}
                      src="/CONECTA.webp"
                      variant="square"
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <b className="gradient gradient--text">
                        Conecta con la información más actualizada en tiempo
                        real
                      </b>
                    }
                    secondary="Con la Guía Intelimotor, tendrás acceso instantáneo a los precios de autos seminuevos respaldados por datos de mercado en constante evolución."
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      sx={{ width: 24, height: 24, mr: 2 }}
                      src="/GRATIS.webp"
                      variant="square"
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <b className="gradient gradient--text">
                        Gratis, y siempre será así
                      </b>
                    }
                    secondary="Porque creemos que todos merecen acceder a información previsa sin tener que pagar por ella."
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      sx={{ width: 24, height: 24, mr: 2 }}
                      src="/EXPLORA.webp"
                      variant="square"
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <b className="gradient gradient--text">
                        Explora sin límites
                      </b>
                    }
                    secondary="Nuestra plataforma intuitiva y fácil de usar te permite realizar consultas ilimitadas, con resultados en segundos."
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      sx={{ width: 24, height: 24, mr: 2 }}
                      src="/DATOS.webp"
                      variant="square"
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <b className="gradient gradient--text">
                        Datos confiables y transparentes
                      </b>
                    }
                    secondary="Nuestro compromiso es proporcionarte información previsa y confiable. Olvídate de las suposiciones, cada precio se basa en análisis de mercado rigurosos."
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Stack>
        <WhatsAppButton />
      </Container>
    </Fade>
  );
}

export default RequestAccess;
