import { Route, Routes } from "react-router-dom";

import Redirector from "./Redirector";
import RequestAccess from "./RequestAccess";
import SignUp from "./SignUp";
import VerifyEmail from "./VerifyEmail";
import GettingStarted from "./GettingStarted";
import SignIn from "./SignIn";
import SignOut from "./SignOut";

function Router() {
  return (
    <Routes>
      <Route path="/" element={<Redirector />} />
      <Route exact path="/request-access" element={<RequestAccess />} />
      <Route exact path="/sign-in" element={<SignIn />} />
      <Route exact path="/sign-out" element={<SignOut />} />
      <Route exact path="/sign-up" element={<SignUp />} />
      <Route exact path="/verify-email" element={<VerifyEmail />} />
      <Route exact path="/getting-started" element={<GettingStarted />} />
    </Routes>
  );
}

export default Router;
