import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import GettingStartedLayout from "./GettingStartedLayout";

function GettingStartedDone({ initialState, prevStep, nextStep }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [goingBack, setGoingBack] = useState(false);

  useEffect(() => {
    if (!goingBack && searchParams.has("reschedule")) {
      setGoingBack(true);
      setSearchParams({});
      prevStep({ rescheduling: true });
    }
  }, [searchParams, goingBack, prevStep, setGoingBack, setSearchParams]);

  return (
    <GettingStartedLayout
      noImages
      nextStep={async () => {
        const result = await nextStep({ done: true }, 0);
        window.location = `https://app.intelimotor.com/sign-in?authToken=${
          result.data.changeGuideGettingStartedState
        }&redirect=${encodeURIComponent("/guide")}`;
      }}
      nextStepText="Ir a la Guía Intelimotor"
      prevStep={() => prevStep({})}
    >
      <Box>
        <Typography variant="h5">¡Está todo listo!</Typography>
        <Typography>
          Estamos emocionados de tenerte a bordo y esperamos que disfrutes de
          todos los beneficios que tenemos para ofrecer. A partir de ahora,
          tendrás acceso a nuestra plataforma.
        </Typography>
      </Box>
    </GettingStartedLayout>
  );
}

export default GettingStartedDone;
